<template>
    <div>
        <v-dialog v-model="visible" persistent max-width="500px">
            
        <v-card class="text-center white--text py-4 px-2" color="primary">
            <h3>Please provide the following details to continue!</h3>
        </v-card>

        <v-card>
        <v-card-text class="px-4">
        <v-form>
            <v-list-item class="">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Upload your child's photograph in school uniform
                <span class="red--text"><strong> * </strong></span>
                </div> 

                <template>  

                <v-col class="text-center">
                    <div>
                    <v-avatar size="100px" class="">
                        <v-img
                        v-if="currentUser.image"
                        :src="currentUser.image"
                        ></v-img>
                    </v-avatar>
                    </div>

                    <v-btn
                    class="mt-2"
                    color="primary"
                    @click="$refs.profileImageField.$refs.input.click()"
                    ><v-icon class="mr-3">mdi-image</v-icon
                    ><span>Upload Image</span
                    ></v-btn>

                    <v-file-input
                    v-show="false"
                    class="ma-0 pa-0"
                    ref="profileImageField"
                    @change="(file) => addProfileImage(file)"
                    accept="image/*"
                    ></v-file-input>
                </v-col>

                </template>
            </v-list-item-content>

            </v-list-item>
        
            <v-list-item class="">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Enter your residential address<span class="red--text"><strong> * </strong></span></div> 
                <v-text-field 
                :rules="[(v) => !!v || v != '' || 'Required']"
                required
                persistent-hint
                outlined
                hide-details="auto"
                v-model="currentUser.address"
                >
                </v-text-field>    
            </v-list-item-content>
            </v-list-item>

            <v-list-item class="">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Set your child's date of birth<span class="red--text"><strong> * </strong></span></div> 
                <div v-if="!currentUser.dob" class="red--text body-2">Not set</div>
                <div v-else class="primary--text body-2">{{ currentUser.dob }}</div>
            </v-list-item-content>

            <v-list-item-icon class="align-self-center">
                <v-btn class="primary align-center" @click="userDetails.dob=true"> Set </v-btn>
            </v-list-item-icon>
            </v-list-item>

            <v-list-item class="" v-if="currentUser.parent_profile">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Upload father's photograph
                <span class="red--text"><strong> * </strong></span>
                </div> 

                <template>  

                <v-col class="text-center">
                    <div>
                    <v-avatar size="100px" class="mb-2">
                        <v-img
                        v-if="currentUser.parent_profile.father_image"
                        :src="currentUser.parent_profile.father_image"
                        ></v-img>
                    </v-avatar>
                    </div>

                    <v-btn
                    class="mt-2"
                    color="primary"
                    @click="$refs.fatherImageField.$refs.input.click()"
                    ><v-icon class="mr-3">mdi-image</v-icon
                    ><span>Upload Image</span
                    ></v-btn>

                    <v-file-input
                    v-show="false"
                    class="ma-0 pa-0"
                    ref="fatherImageField"
                    @change="(file) => addFatherProfileImage(file)"
                    accept="image/*"
                    ></v-file-input>
                </v-col>

                </template>
            </v-list-item-content>

            </v-list-item>

            <v-list-item class="" v-if="currentUser.parent_profile">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Enter father's name
                <span class="red--text"><strong> * </strong></span>
                </div> 
                <v-text-field 
                :rules="[(v) => !!v || v != '' || 'Required']"
                required
                hide-details="auto"
                persistent-hint
                outlined
                v-model="currentUser.parent_profile.father_name"
                >
                </v-text-field>    
            </v-list-item-content>
            </v-list-item>

            <v-list-item class="" v-if="currentUser.parent_profile">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Upload mother's photograph
                <span class="red--text"><strong> * </strong></span>
                </div> 

                <template>  

                <v-col class="text-center">
                    <div>
                    <v-avatar size="100px" class="mb-2">
                        <v-img
                        v-if="currentUser.parent_profile.mother_image"
                        :src="currentUser.parent_profile.mother_image"
                        ></v-img>
                    </v-avatar>
                    </div>

                    <v-btn
                    class="my-2"
                    color="primary"
                    @click="$refs.motherImageField.$refs.input.click()"
                    ><v-icon class="mr-3">mdi-image</v-icon
                    ><span>Upload Image</span
                    ></v-btn>

                    <v-file-input
                    v-show="false"
                    class="ma-0 pa-0"
                    ref="motherImageField"
                    @change="(file) => addMotherProfileImage(file)"
                    accept="image/*"
                    ></v-file-input>
                </v-col>

                </template>
            </v-list-item-content>

            </v-list-item>

            <v-list-item class="" v-if="currentUser.parent_profile">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Enter mother's name
                <span class="red--text"><strong> * </strong></span>  
                </div> 
                <v-text-field 
                :rules="[(v) => !!v || v != '' || 'Required']"
                required
                persistent-hint
                outlined
                hide-details="auto"
                v-model="currentUser.parent_profile.mother_name"
                >
                </v-text-field>    
            </v-list-item-content>
            </v-list-item>

            <v-list-item class="">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Provide alternate phone number<span class="red--text"><strong> * </strong></span></div> 
                <v-text-field 
                :rules="[(v) => !!v || v != '' || 'Required']"
                required
                persistent-hint
                outlined
                v-model="currentUser.fathers_phone"
                hide-details="auto"
                >
                </v-text-field>    
            </v-list-item-content>
            </v-list-item>

            <v-list-item class="">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Select blood group (optional)</div> 
                <v-select
                :items="bloodGroupOptions"
                v-model="currentUser.blood_group"
                label="Outlined style"
                hide-details="auto"
                outlined
                ></v-select>
            </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item class="">
            <v-list-item-content>
                <div class="black--text subtitle-1 font-weight-bold">Unique identity number</div> 
                <v-text-field 
                persistent-hint
                outlined
                hide-details="auto"
                v-model="currentUser.aadhar"
                placeholder="Aadhar/Birth Certificate no"
                >
                </v-text-field>    
            </v-list-item-content>
            </v-list-item> -->


            <!-- 'users/default/default.jpg' -->
            <v-list-item>
            <v-btn
                class="primary"
                block
                :disabled="
                !currentUser.dob || 
                !currentUser.address || 
                !currentUser.parent_profile.father_name ||
                !currentUser.parent_profile.mother_name || 
                !(currentUser.image && !currentUser.image.includes('users/default/default.jpg')) ||
                !(currentUser.parent_profile.father_image && !currentUser.parent_profile.father_image.includes('users/default/default.jpg')) ||
                !(currentUser.parent_profile.mother_image && !currentUser.parent_profile.mother_image.includes('users/default/default.jpg')) ||
                (false && !currentUser.aadhar) ||
                !currentUser.fathers_phone || 
                (false && !currentUser.blood_group)
                "
                @click="setProfileDetails"
            >Save</v-btn>
            </v-list-item>
            </v-form>
            </v-card-text>
        </v-card>
        </v-dialog>

        <image-cropper-dialog
            ref="cropperDialog"
            :uploadedImage="uploadedImage"
            @onCrop="(croppedImage) => {
                this.currentUser.image = croppedImage;
            }"
        />

        <image-cropper-dialog
            ref="fatherImageCropperDialog"
            v-if="this.currentUser.parent_profile"
            :uploadedImage="uploadedFatherImage"
            @onCrop="(croppedImage) => {
                this.currentUser.parent_profile.father_image = croppedImage;
            }"
        />

        <image-cropper-dialog
            ref="motherImageCropperDialog"
            v-if="this.currentUser.parent_profile"
            :uploadedImage="uploadedMotherImage"
            @onCrop="(croppedImage) => {
                this.currentUser.parent_profile.mother_image = croppedImage;
            }"
        />

        <v-dialog v-model="userDetails.dob" persistent max-width="500px">
            <v-card class="text-center white--text py-4 px-2" color="primary">
                <h3>Please set your birthday!</h3>
            </v-card>
            <v-date-picker v-model="currentUser.dob" full-width>
                <v-spacer></v-spacer>

                <v-btn color="primary" @click="userDetails.dob=false" :disabled="!currentUser.dob">Ok</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import { mapGetters, mapActions } from "vuex";
import Mixins from "@utils/mixins";

export default {
    name: "ProfileEditDialog",
    components: {
        ImageCropperDialog,

    },
    mixins: [Mixins.essentials],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            uploadedImage: null,
            uploadedFatherImage: null,
            uploadedMotherImage: null,
            bloodGroupOptions: [
                "A+",
                "A-",
                "B+",
                "B-",
                "O+",
                "O-",
                "AB+",
                "AB-"
            ],
            userDetails: {
                dob: false,
                address: false, 
                image: false, 
                father_image: null,
                father_name: null,
                mother_name: null,
                mother_image: null,
                aadhar: null,
                blood_group: null,
                fathers_phone: null,
            }, 
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
    methods:{
        ...mapActions([
            "showSnackbar",
            "setCurrentUser",
        ]),

        async setProfileDetails() {
            var url = this.endpoints.userProfileViewSet + this.currentUser.id + "/";
            var response = await this.api.call(
                this.essentials,
                url,
                this.api.Methods.PATCH,
                { 
                    image: this.currentUser.image, 
                    address: this.currentUser.address, 
                    dob: this.currentUser.dob,
                    father_image: this.currentUser.parent_profile.father_image,
                    mother_image: this.currentUser.parent_profile.mother_image, 
                    father_name: this.currentUser.parent_profile.father_name,
                    mother_name: this.currentUser.parent_profile.mother_name,
                    fathers_phone: this.currentUser.fathers_phone,
                    blood_group: this.currentUser.blood_group,
                    aadhar: this.currentUser.aadhar
                }
            );
            if (response) {
                this.$emit("close")
            }
        },

        async addProfileImage(file) {
            if (!file) return;
            // var image_object = {
            //   document: await this.Helper.toBase64(file),
            //   file_name: file.name,
            // };
            this.uploadedImage = await this.Helper.toBase64(file);
            this.$refs.cropperDialog.initCropper(file.type);
            // console.log(this.currentUser.image)
            // this.$set(this.currentUser.image, "image", image_object.document);
        },

        async addFatherProfileImage(file){
            if (!file) return;
            this.uploadedFatherImage = await this.Helper.toBase64(file);
            this.$refs.fatherImageCropperDialog.initCropper(file.type);
        },

        async addMotherProfileImage(file){
            if (!file) return;
            this.uploadedMotherImage = await this.Helper.toBase64(file);
            this.$refs.motherImageCropperDialog.initCropper(file.type);
        }


        
    }
}
</script>